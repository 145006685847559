<template>
    <modal ref="crearPromo" :titulo="titulo" tamano="modal-xl" icon="porcent" :cargando="loading" @guardar="guardar">
        <div class="px-4">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-content-center">
                    <div class="col-5">
                        <div class="row mx-0 my-3">
                            <div class="col px-0">
                                <ValidationProvider v-slot="{errors}" :rules="{required:true,max:40,duplicado:unicoNombre}" name="nombre">
                                    <label class="ml-2 text-general">Nombre de la promoción</label>
                                    <el-input v-model="model.nombre" :suffix-icon="false?'icon-attention-alt text-danger':''" maxlength="41" />
                                    <span v-show="false" class="text-danger w-100 f-11">Lo sentimos, ya existe una promoción con este nombre</span>
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-0 my-3">
                            <div class="col px-0">
                                <ValidationProvider v-slot="{errors}" rules="required|max:500" name="condiciones">
                                    <label class="ml-2 text-general">Condiciones</label>
                                    <el-input v-model="model.condiciones" type="textarea" :rows="3" maxlength="501" />
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-0 my-3">
                            <div class="col px-0">
                                <ValidationProvider v-slot="{errors}" rules="required" name="tipo">
                                    <label class="ml-2 text-general">Tipo de promoción</label>
                                    <el-select v-model="model.tipo" class="w-100" :disabled="editarProm">
                                        <el-option
                                        v-for="(item, idx2) in options"
                                        :key="idx2"
                                        :label="item.label"
                                        :value="item.value"
                                        >
                                            <span style="float: left">{{ item.label }}</span>
                                        </el-option>
                                    </el-select>
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row my-4">
                            <div class="col">
                                <p class="font-weight-bold f-15 text-general">Puede cambiar el precio a cada producto dentro de la promoción</p>
                            </div>
                        </div>
                        <div class="row mx-0 my-3">
                            <div v-if="model.tipo===2 || model.tipo===5" class="col-6 px-1">
                                <label class="ml-2 f-14 text-general">Texto
                                    <el-tooltip class="item" effect="light" placement="bottom">
                                        <div slot="content" class="text-center" style="max-width:170px;">
                                            El {{ $config.cliente }} visualizará este texto como etiqueta en los productos aplicados con esta promoción.
                                        </div>
                                        <i class="icon-info-circled f-14" />
                                    </el-tooltip>
                                </label>
                                <ValidationProvider v-slot="{errors}" :rules="`${model.tipo===2 || model.tipo===5 ? 'required|max:12' : ''}`" name="texto">
                                    <el-input v-model="model.texto" class="w-100" maxlength="13" />
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                            <div v-else-if="model.tipo===3" class="col-4 px-1">
                                <label class="ml-2">Descuento</label>
                                <ValidationProvider v-slot="{errors}" :rules="`${model.tipo===3? 'required|max_value:100' : 'max_value:100'}`" name="descuento">
                                    <el-input v-model="model.descuento" placeholder="%" class="w-100" maxlength="4" />
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                            <div class="col-auto px-1 text-center pb-1 pt-4">
                                <el-checkbox v-model="model.borde" class="mt-2 check-red" :true-label="1" :false-label="0">
                                    Borde en producto
                                </el-checkbox>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col">
                                <p class="font-weight-bold text-general">Previsualización de ejemplo</p>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <cardProductoVer :info="model" class="p-2 mx-2 f-15 overflow-auto custom-scroll" style="max-height: calc(100% - 80px);" />
                        </div>
                    </div>
                    <div class="col-5">
                        <div class="row mx-0 justify-center">
                            <slim-cropper ref="cropBanner" :options="slimOptions" class="border" style="height:160px;width:400px;background:#F8F9FF;">
                                <div slot="label" class="">
                                    <img src="/img/modales/camera.svg" alt="" />
                                </div>
                            </slim-cropper>
                        </div>
                        <div class="row mx-0 justify-content-end f-12">
                            Relación 2.5:1, Imagen de la promoción
                        </div>
                        <div class="row mx-0 justify-content-end f-12 my-3">
                            <div class="col">
                                <el-checkbox v-model="model.estado" class="mb-2 check-red" :true-label="1" :false-label="0">
                                    Activa
                                </el-checkbox>
                                <el-checkbox v-model="model.categorias" class="check-red" :disabled="modoEditar" :true-label="1" :false-label="2" @change="cambioTipoCategoria">
                                    Cualquier Categoría
                                </el-checkbox>
                            </div>
                        </div>
                        <div class="row mx-0 justify-content-end my-3">
                            <div class="col">
                                <label class="ml-2">Categorias</label>
                                <ValidationProvider v-slot="{errors}" :rules="`${model.categorias===2 ? '' : ''}`" name="categorias">
                                    <el-select v-model="selectCat" placeholder="Seleccionar categoria" class="w-100" :disabled="model.categorias===1" @change="addCategoria">
                                        <el-option
                                        v-for="(item,i) in lista_categorias"
                                        :key="i"
                                        :label="item.nombre"
                                        :value="item.id"
                                        :disabled="item.disabled"
                                        />
                                    </el-select>
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-0 f-12 my-2 px-3">
                            <div v-for="(info, idx) in categoriasvirtuales" :key="`cat-${idx}`" class="br-20 mx-1 bg-general3 my-1 text-white f-17 px-2">
                                {{ info.nombre }} <span v-if="info.cant_productos > 0"> ({{ info.cant_productos }}) </span>
                                <i v-if="!modoEditar && info.cant_productos <= 0" class="icon-cancel cr-pointer f-18 ml-1" @click="eliminarCategoriaVirtual(idx, info)" />
                            </div>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </modal>
</template>

<script>
import Promocion from '~/services/promociones/promociones'
import Aws from '~/services/aws'
import {mapGetters} from 'vuex'
import moment from 'moment'
import { extend } from 'vee-validate';

extend('duplicado', {
    validate(value, items){
        let existe = false;
        items.forEach(info =>{
            if(_.toLower(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "") == _.toLower(info.nombre).normalize("NFD").replace(/[\u0300-\u036f]/g, "")){
                existe = true;
            }
        });
        if(existe){
            return false
        }else{
            return true
        }
    },
    message:
    "Ya existe otra promoción con el mismo nombre."
});

export default {
    props:{
        tenderoVip:{
            type: Boolean,
            default: false
        },
        visible:{
            type: Number,
            default: 1
        },
        editarProm: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            loading: false,
            informacion: '',
            explicacion: '',
            model: {
                id: null,
                nombre: '',
                condiciones: '',
                tipo: 2,
                color:2,
                borde: 1,
                posicion: 2,
                texto: '',
                descuento: 0,
                estado: 1,
                categorias: 1,
                promociones_categorias: [],
                imagen: null
            },
            selectCat: null,
            colores: [ '','bg-general', 'bg-grr-red', 'bg-grr-purple', 'bg-grr-pink' ],
            border: [ 'border-blue', 'border-red', 'border-purple', 'border-pink' ],
            borde_activo: 'border-red',
            options: [
                // { value: 1, label: '2x1' },
                { value: 2, label: 'Rebajado' },
                { value: 3, label: 'Descuento fijo' },
                { value: 4, label: 'Descuendo variable' },
                { value: 5, label: 'Destacado' }
            ],
            slimOptions: {
                ratio: '5:2',
                label: '',
                size: { width: 600, height: 800 },
                minSize: { width: 50, height: 50 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            lista_categorias: [],
            categoriasvirtuales: [],
            modoEditar: false,
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
            titulo: 'Crear promoción',
            rutas_admin: ['admin-vip.almacen.promociones', 'admin.almacen.promociones'],
            vaEditar: false,
            visible_color: false,
            resizedImg: null,
            originalImg: null

        }
    },
    computed:{
        ...mapGetters({
            promociones: 'promociones/promociones/promociones',
        }),
        datosCar(){
            return{ color: this.model.color, border: this.model.borde, posicion: this.model.posicion, texto: this.model.texto  }
        },
        unicoNombre(){
            if(this.model.id !== null){
                return this.promociones.filter(e => e.id !== this.model.id)
            }
            return this.promociones
        }
    },
    methods: {
        async toggle(info){
            await this.limpiar()
            this.titulo = 'Crear promoción'
            if(info !== null && info !== undefined){
                console.log(info);
                this.titulo = 'Editar promoción'
                this.vaEditar = true

                /* if(info.cant_tiendas > 0){
                    this.modoEditar = true
                } */
                this.model.id = info.id
                this.model.nombre = info.nombre
                this.model.condiciones = info.condiciones
                this.model.tipo =  info.tipo
                this.model.estado =  info.estado
                this.model.borde = info.borde
                this.seleccionarColorBorde()
                if(info.texto !== null){
                    this.model.texto = info.texto
                }
                this.model.texto= info.texto
                if(info.descuento !== null){
                    this.model.descuento = info.descuento
                }
                this.model.visible = info.visible
                this.model.categorias = info.categorias

                if(this.visible!==2){
                    await this.listarCategorias()
                    this.categoriasvirtuales = info.promo_categorias
                    this.lista_categorias.map(e =>{
                        this.categoriasvirtuales.map(v =>{
                            if(e.id ===v.id_categoria){
                                e.disabled = true
                            }
                        })
                    })
                }
                if (info.imagen != null){
                    this.$refs.cropBanner.set_image(info.imagen_show)
                } else {
                    this.$refs.cropBanner.instanciaCrop.remove()
                }
                if(this.tenderoVip){
                    let ini = moment(info.inicio).format('YYYY-MM-DD')
                    let fin = moment(info.fin).format('YYYY-MM-DD')
                    this.model.fechas = [ini, fin]
                    this.model.id_promo_tienda = info.id_promo_tienda
                    this.modoEditar = info.pro_agregados > 0
                }
            }else{
                this.listarCategorias()
            }

            this.$refs.crearPromo.toggle()
        },
        async guardar(){
            try {


                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                if(this.model.categorias===2 && this.categoriasvirtuales.length <= 0){
                    this.notificacion('Mensaje', 'categorias son  obligatorios', 'warning')
                    return false
                }
                // if(this.tenderoVip && this.visible !== 2){
                //     this.model.id_tienda = this.$usuario.tienda.id
                // }

                const resp = this.categoriasvirtuales.map( e => e.id_categoria )
                this.model.promociones_categorias = resp
                const archivo = this.$refs.cropBanner.instanciaCrop.dataBase64.output.image
                // const url = await this.uploadCropperImage(archivo, 'promociones')

                this.model.imagen = archivo? archivo : null

                this.loading = true
                if(this.vaEditar){
                    const {data} = await Promocion.editarPromocion(this.model)
                    /* this.editarProm = false */
                    this.notificacion('Mensaje', 'Editado exitosamente', 'success')
                }else{
                    const {data} = await Promocion.crearPromocion(this.model)
                    this.notificacion('Mensaje', 'Guardado exitoso', 'success')
                }
                this.loading = false
                if(this.$route.name === 'admin.promociones'){
                    this.$store.dispatch('promociones/promociones/action_listar_promociones')
                    this.$emit('actualizar')
                }else if(this.$route.name === 'tendero-vip.promociones'){
                    this.$emit('actualizar')
                }else if(this.rutas_admin.includes(this.$route.name)){
                    this.$store.dispatch('promociones/promociones/action_listar_promociones_almacen',{ visible: 2})
                    this.$emit('actualizar')
                }
                this.$refs.crearPromo.toggle()
            } catch (e){
                this.loading = false
                this.error_catch(e)
            }
        },
        async listarCategorias(){
            try {
                var id_tienda = null
                if(this.tenderoVip){
                    id_tienda = this.$usuario.tienda.id
                }
                const {data} = await Promocion.listarCategorias(id_tienda)
                this.lista_categorias = data.categorias
                // this.addCategoria()

            } catch (e){
                this.error_catch(e)
            }
        },
        addCategoria(){
            let categoryFound = this.lista_categorias.find(i => i.id === this.selectCat)
            this.lista_categorias.map(e =>{
                if(e.id ===this.selectCat){
                    e.disabled = true
                }
            })
            this.categoriasvirtuales.push({
                id: null,
                id_categoria: categoryFound.id,
                nombre: categoryFound.nombre,
                cant_productos: 0
            })
        },
        eliminarCategoriaVirtual(idx, info){
            this.categoriasvirtuales.splice(idx,1);
            this.lista_categorias.map(e =>{
                if(e.id ===info.id_categoria){
                    e.disabled = false
                }
            })
        },
        cambioTipoCategoria(){
            if(this.model.categorias===1){
                this.model.promociones_categorias = null
                this.categoriasvirtuales = []
            }
        },
        seleccionarColorBorde(){
            const color = this.model.color
            this.borde_activo = this.border[color-1]
        },

        limpiar(){
            this.loading = false
            this.modoEditar = false
            this.vaEditar = false
            this.model = {
                id: null,
                nombre: '',
                condiciones: '',
                tipo: 2,
                borde: 1,
                texto: '',
                descuento: 0,
                estado: 1,
                categorias: 1,
                promociones_categorias: [],
                imagen: null,
                fechas:[],
            }
            this.lista_categorias = []
            this.categoriasvirtuales = []
            this.$refs.cropBanner.instanciaCrop.remove()
            this.$refs.validacion.reset()
            this.visible_color = false
        }
    }
}
</script>
<style lang="css" scoped>

.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}
</style>
